// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import DynamicTextImage from 'components/DynamicTextImage'
import cn from 'classnames'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import Content from 'components/Content'

import styles from './styles.module.scss'

import themes, { Theme } from './themes/__supportedThemes'

Header.defaultProps = {
    date: new Date()
}

type Props = {
    date: string,
    children: any,
    theme: Theme
}

function wrapText (string: string = '', maxLength: number = 16) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n')
}

function Header (props: Props) {
    const { theme, handlePlayVideo } = props
    const { state } = React.useContext(Store)
    const name = state.lead.fields.firstName
    const address = state.campaign.client.address
    const { t } = useTranslation(transDomain)
    const isConquest = !state.lead.fields.firstName || state.lead.fields.firstName === ''
    const text = props.theme.dynamicTextImage ? wrapText(state.lead.fields[props.theme.dynamicTextImage.fieldsKey]) : null

    return (
        <div className={cn(styles.container, {
            [styles.hiddenStats]: theme.hiddenStats
        })} style={{
            backgroundColor: theme.containerColor
        }}>
            <div className={cn(styles.topBar, {
                [styles.isLighterText]: theme.isLighterText
            })} style={{
                color: theme.color,
                backgroundColor: theme.backgroundColor
            }}>
                {theme.baseContactInformation
                    ? <span>
                        {theme.contactInformation.name && state.campaign.contactInfo.name ? <strong>{state.campaign.contactInfo.name} | </strong> : null}
                        {theme.contactInformation.position && state.campaign.contactInfo.position ? `${state.campaign.contactInfo.position} | ` : null}
                        {theme.contactInformation.phone && state.campaign.contactInfo.phone ? <>Ph: <a href={'tel:' + state.campaign.contactInfo.phone}>{state.campaign.contactInfo.phone}</a> | </> : null}
                        {theme.contactInformation.email && state.campaign.contactInfo.email ? <>Em: <a href={'mailto:' + state.campaign.contactInfo.email}>{state.campaign.contactInfo.email}</a></> : null}
                    </span>
                    : <span>{theme.contactInformation.address && [((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                        address.city,
                        address.province,
                        address.postalCode
                    ].join(' ')}
                        {theme.contactInformation.website && state.campaign.client.website ? <a href={state.campaign.client.website} target="_blank" rel="noopener noreferrer">{state.campaign.client.website}</a> : null}
                        {theme.contactInformation.phone && state.campaign.contactInfo.phone ? ` | Ph: ${state.campaign.contactInfo.phone}` : null}
                        {theme.contactInformation.email && state.campaign.contactInfo.email ? ` | Em: ${state.campaign.contactInfo.email}` : null}
                    </span>}
            </div>
            <div className={styles.containerGrid}>
                <div className={styles.leftSide}>
                    <div className={cn(styles.imgContainer, {
                        [styles.isLogan2103Logo]: theme.isLogan2103Logo,
                        [styles.isLogan2105Logo]: theme.isLogan2105Logo,
                        [styles.isLamar2103Logo]: theme.isLamar2103Logo,
                        [styles.isWayland2104Logo]: theme.isWayland2104Logo,
                        [styles.isSalisbury2105Logo]: theme.isSalisbury2105Logo,
                        [styles.isUNMF2104Logo]: theme.isUNMF2104Logo,
                        [styles.isUNMF2105Logo]: theme.isUNMF2105Logo,
                        [styles.isSMSU2103Logo]: theme.isSMSU2103Logo,
                        [styles.isAADC2105logo]: theme.isAADC2105logo,
                        [styles.isEVCC2106Logo]: theme.isEVCC2106Logo,
                        [styles.isFUS2105Logo]: theme.isFUS2105Logo,
                        [styles.isWBU2106Logo]: theme.isWBU2106Logo,
                        [styles.isSMSU2106Logo]: theme.isSMSU2106Logo,
                        [styles.isAADC2109logo]: theme.isAADC2109logo,
                        [styles.isNEMCC2109Logo]: theme.isNEMCC2109Logo,
                        [styles.dc2111Logo]: theme.dc2111Logo,
                        [styles.isAADC2202logo]: theme.isAADC2202logo,
                        [styles.isTexasam2111Logo]: theme.isTexasam2111Logo,
                    })}>
                        <span style={{
                            backgroundImage: `url(${theme.logo})`
                        }}/>
                    </div>
                    {isConquest ? (
                        <Content
                            className={cn(styles.title, {
                                [styles.isBoldTitle]: theme.isBoldTitle,
                                [styles.isLargeWidth]: theme.isLargeWidth,
                                [styles.isBigFontSize]: theme.isBigFontSize,
                                [styles.isBlackTitle]: theme.isBlackTitle,
                                [styles.isdc2111Title]: theme.isdc2111Title,
                            })}
                            style={{
                                color: theme.titleColor
                            }}
                            contentKey='masthead.headingEmpty'
                            wrapper='h2'
                        >
                            Thank you for being a part of their story.
                        </Content>
                    ) : (

                        <Content
                            className={cn(styles.title, {
                                [styles.isBoldTitle]: theme.isBoldTitle,
                                [styles.isLargeWidth]: theme.isLargeWidth,
                                [styles.isBigFontSize]: theme.isBigFontSize,
                                [styles.isBlackTitle]: theme.isBlackTitle,
                                [styles.isdc2111Title]: theme.isdc2111Title,
                                [styles.isdc2202Title]: theme.isdc2202Title,
                            })}
                            style={{
                                color: theme.titleColor
                            }}
                            contentKey='masthead.heading'
                            wrapper='h2'
                        >
                            {name}, thank you for being a part of their story.
                        </Content>
                    )}

                    {!props.theme.hiddenMastheadMessage && (
                        <Content
                            className={cn(styles.p, {
                                [styles.isLighterP]: theme.isLighterP,
                                [styles.isLightP]: theme.isLightP,
                                [styles.isdc2111P]: theme.isdc2111P,
                                [styles.istexasam2111P]: theme.istexasam2111P
                            })}
                            contentKey='masthead.message'
                            wrapper='p'
                            style={{
                                color: theme.paragraphColor
                            }}
                        >
                            Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive. Thank you so
                            very much for your generosity.
                        </Content>
                    )}
                </div>

                {theme.dynamicTextImage
                    ? (
                        <DynamicTextImage
                            backgroundImage={theme.dynamicTextImage.background}
                            text={
                                isConquest
                                    ? t(props.theme.dynamicTextImage.translationConquestKey, { text })
                                    : t(props.theme.dynamicTextImage.translationKey, { text })
                            }
                            onClick={handlePlayVideo}
                            position={theme.dynamicTextImage.position}
                            font={theme.dynamicTextImage.font}
                            className={cn(styles.img, {
                                [styles.dynamicTextImage]: theme.dynamicTextImage
                            })}
                        />
                    )
                    : (
                        <div className={styles.img} style={{
                            backgroundImage: `url(${theme.backgroundImage})`
                        }}>
                            <span className={styles.imgLayer}>
                                {theme.playIcon && (
                                    <div className={styles.playLogo} style={{
                                        backgroundImage: `url(${theme.playIcon})`
                                    }} onClick={handlePlayVideo} />
                                )}
                                <div className={styles.signatureBox}>
                                    <div className={cn(styles.signature, {
                                        [styles.isLogan2103Signature]: theme.isLogan2103Signature,
                                        [styles.isLogan2105Signature]: theme.isLogan2105Signature
                                    })} style={{
                                        backgroundImage: `url(${theme.signature})`
                                    }}/>
                                </div>
                            </span>
                        </div>
                    )
                }
            </div>
            <>
                {!theme.hiddenStats && props.children}
            </>
        </div>
    )
}

export default withTheme(themes)(Header)
