// @flow
import React, { useCallback } from 'react'

import { Store } from 'Store'
import { sendAutoresponder } from 'Actions'

import styles from './styles.module.scss'
import MatchingGiftForm from '../MatchingGift/Form'
import ModalContainer from '../ModalContainer'
import cn from 'classnames'
import Button from '../Button'
import { Currency } from '../../i18n/NumberFormatter'
import withTheme from '../../hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import { withRouter } from 'react-router-dom'

export default withRouter(withTheme(supportedThemes)(function SuccessModal ({
    location,
    match,
    history,
    theme,
    paymentResponse
}) {
    const { state, dispatch } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)

    React.useEffect(() => {
        (async () => {
            if (state.campaign.hasOwnProperty('autoresponder')) {
                await sendAutoresponder(dispatch)
            }
        })()
    }, [dispatch, state.campaign])

    const handleClick = useCallback(
        () => {
            const currentUrl = window.location.pathname + window.location.search
            const homeUrl = `/${match.params.url}${location.search}`
            if (currentUrl === homeUrl) {
                window.location.reload()
            } else {
                history.push(homeUrl)
                window.scrollTo(0, 0)
            }
        },
        [ history, location, match ]
    )

    const name = theme.hasPrimarysalutation ? state.lead.fields.primarysalutation : state.lead.fields.firstName

    React.useEffect(() => {
        if (window.doublethedonation && state.campaign['matching-gift-gateway'] && paymentResponse) {
            window.DDCONF = {
                API_KEY: state.campaign['matching-gift-gateway'].publicKey,
                COMPANY: state.lead.fields.matchingGiftCompanyId
            }
            window.doublethedonation.plugin.load_plugin()
            window.doublethedonation.plugin.load_config()
            window.doublethedonation.plugin.set_donation_identifier(paymentResponse.id)
            window.doublethedonation.plugin.set_donation_campaign(state.campaign.name)
            if (state.lead.fields.matchingGiftCompanyId) {
                window.doublethedonation.plugin.set_company(state.lead.fields.matchingGiftCompanyId)
            } else {
                const domain = window.doublethedonation.integrations.core.strip_domain(state.lead.fields.email)
                window.doublethedonation.plugin.email_domain(domain)
            }
        }
    }, [ paymentResponse, state.campaign, state.lead ])

    return <>
        {state.campaign['matching-gift-gateway'] && <MatchingGiftForm/>}

        <ModalContainer
            show={true}>
            <div className={styles.body} style={{
                '--primary-font': theme.primaryFont,
                '--secondary-font': theme.secondaryFont,
                '--ternary-font': theme.ternaryFont,
                '--primary-color': theme.primaryColor,
                '--secondary-color': theme.secondaryColor,
                '--button-text-color': theme.buttonTextColor,
            }}>
                <div className={styles.topImage} style={{
                    backgroundImage: `url(${theme.backgroundImage})`
                }}>
                    {theme.modalLogo && (
                        <div className={cn(styles.modalLogo, {
                            [styles.isLogan2103ModalLogo]: theme.isLogan2103ModalLogo,
                            [styles.isLamarModal2103Logo]: theme.isLamarModal2103Logo,
                            [styles.isWayland2104Logo]: theme.isWayland2104Logo,
                            [styles.isSalisbury2105Logo]: theme.isSalisbury2105Logo,
                            [styles.isEVCC2106Logo]: theme.isEVCC2106Logo,
                            [styles.isFUS2105Logo]: theme.isFUS2105Logo,
                        })} style={{
                            backgroundImage: `url(${theme.modalLogo})`
                        }}/>
                    )}
                </div>
                <div className={styles.description}>
                    <div>
                        <h2 className={cn({
                            [styles.isBoldTitle]: theme.isBoldTitle,
                            [styles.isBlackTitle]: theme.isBlackTitle,
                        })} style={{
                            color: theme.titleColor
                        }}>
                            Thank you, {name}.
                        </h2>
                        <p className={cn({
                            [styles.isLighterText]: theme.isLighterText
                        })} style={{
                            color: theme.textColor
                        }}>
                            Your {currencyFormatter.format(state.lead.fields.donationAmount)} donation has been submitted.
                        </p>
                    </div>
                    <div>
                        {state.campaign['matching-gift-gateway'] && <div id="dd-container"/>}
                        <Button
                            className={styles.btn}
                            onClick={handleClick}
                            >
                            End your session
                        </Button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    </>
}))
