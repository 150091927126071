// @flow
import * as React from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import MatchingGiftInput from 'components/MatchingGift/Input'
import DonateOption from 'components/DonateOption'
import * as Form from 'components/Form'

import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        donationType: ?string,
        customDonation: ?string,
        donationAmount: ?string,
        cardType: ?string,
        matchingGift: ?string,
        comments: ?String
    }
}

function LeadGenForm (props: Props) {
    const { handleFieldChange, values } = props
    const [loading, setLoading] = React.useState(false)
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const [ otherAmountActive, setOtherAmountActive ] = React.useState(null)

    values.anonymousDonation = values.anonymousDonation || false

    const handleSubmit = React.useCallback(e => {
        e.target.click()
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 2500)
    }, [])

    console.log('loading: ', loading)

    const currencyFormatter = React.useMemo(
        () => Currency(state.currency, state.locale),
        [state.currency, state.locale]
    )

    const chunkArray = (array, size) => {
        const chunkedArr = []
        let index = 0
        while (index < array.length) {
            chunkedArr.push(array.slice(index, size + index))
            index += size
        }
        return chunkedArr
    }

    const handleSetOtherAmountActive = React.useCallback(() => {
        setOtherAmountActive(true)
    }, [])

    const handleSetOtherAmountDisable = React.useCallback(() => {
        setOtherAmountActive(false)
    }, [])

    const donationAmountOptions = React.useMemo(() => {
        const temp = state.campaign.website.donationAmounts.filter(Boolean)
        temp.push(null)
        return temp
    }, [state.campaign.website.donationAmounts])

    const onDonationOption1 = React.useCallback(value => {
        handleFieldChange('donationType', 'one-time')
    }, [handleFieldChange])

    const onDonationOption2 = React.useCallback(value => {
        handleFieldChange('donationType', 'monthly')
    }, [handleFieldChange])

    const handleComments = React.useCallback(value => {
        return handleFieldChange('comments', value)
    }, [handleFieldChange])

    const defaultYearOptions = React.useMemo(() => {
        const maxDate = new Date().getFullYear()
        const dates = {}

        for (let i = maxDate; i >= 1900; i--) {
            dates[i] = i
        }

        return dates
    }, [])

    React.useEffect(() => {
        if (state.campaign['matching-gift-gateway']) {
            window.addEventListener('doublethedonation_company_id', function (event) {
                const companyId = event.detail
                const companyName = document.getElementsByName('doublethedonation_entered_text')[0]
                if (companyId && companyName) {
                    handleFieldChange('matchingGiftCompanyId', companyId, () => {
                        handleFieldChange('matchingGiftCompanyName', companyName.value)
                    })
                }
            }, false)
        }
    }, [state.campaign, handleFieldChange])

    React.useEffect(() => {
        if (props.theme.onlyOneTimeDonation) {
            onDonationOption1()
        }
    }, [onDonationOption1, props.theme.onlyOneTimeDonation])

    React.useEffect(() => {
        if (values.donationAmount === null || !donationAmountOptions.includes(values.donationAmount)) {
            handleSetOtherAmountActive()
        }
    }, [ values.donationAmount, donationAmountOptions, handleSetOtherAmountActive ])

    return (
        <div style={{
            '--title-color': props.theme.titleColor,
            '--primary-color': props.theme.primaryColor,
            marginTop: '-30px'
        }}>
            {props.theme.hasUniqueTitle && (
                <Form.Row>
                    <Form.RowColumns size={1}>
                        <div className={styles.uniqueTitle} style={{
                            color: props.theme.primaryColor
                        }}>
                            {t(props.theme.uniqueTitleTranslationKey)}
                        </div>
                    </Form.RowColumns>
                </Form.Row>
            )}

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    {!props.theme.onlyOneTimeDonation && (
                        <>
                            {!props.theme.hiddenFrequencyTitle ? (
                                <span className={cn(styles.title, {
                                    [styles.isBoldTitle]: props.theme.isBoldTitle,
                                    [styles.isTexasam]: props.theme.isTexasam
                                })}>
                                    {t('frequency')}
                                </span>
                            ) : <div className={styles.titleSeparator} />}

                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <div className={styles.donateOptionGroup}>
                                        <DonateOption
                                            center
                                            small
                                            title={t('donateOptionGroup.one-time')}
                                            onClick={onDonationOption1}
                                            active={values.donationType === 'one-time'}
                                        />
                                        {!props.theme.onlyOneTimeDonation && <DonateOption
                                            center
                                            small
                                            title={t('donateOptionGroup.monthly')}
                                            onClick={onDonationOption2}
                                            active={values.donationType === 'monthly'}
                                        />}
                                    </div>
                                </Form.Field>

                                <div className={cn(styles.spanText, {
                                    [styles.isBoldTitle]: props.theme.isBoldTitle,
                                    [styles.isSmallSpan]: props.theme.isSmallSpan
                                })} style={{ color: props.theme.donationTypeTextColor }}>
                                    {t(props.theme.donationTypeTranslationKey, {
                                        type: values.donationType
                                    })}
                                </div>
                            </Form.RowColumn>
                        </>
                    )}

                    {!props.theme.hiddenDonationAmounts && (
                        <>
                            <span className={cn(styles.title, {
                                [styles.isBoldTitle]: props.theme.isBoldTitle,
                                [styles.isTexasam]: props.theme.isTexasam
                            })}>{t(props.theme.title3FieldKey)}</span>
                            <Form.RowColumn size={1} className={styles.donationAmountsRow}>
                                <Form.Field>
                                    <div className={styles.donationAmounts}>
                                        {chunkArray(donationAmountOptions, 3).map((chunk, key) => {
                                            return chunk.map((donationAmount, i) => (
                                                donationAmount !== null
                                                    ? <DonateOption key={i} center small title={currencyFormatter.format(donationAmount)}
                                                            onClick={() => {
                                                            // if a user changes the donation amount after he selected a designation
                                                                if (values.donationDesignations && values.donationDesignations.length === 1) {
                                                                    const designation = values.donationDesignations[0]
                                                                    designation.value = donationAmount
                                                                    handleSetOtherAmountDisable()
                                                                    // update first field, use callback to update extra state
                                                                    handleFieldChange('donationAmount', donationAmount, () => {
                                                                        handleFieldChange('donationDesignations', [designation])
                                                                    })
                                                                } else {
                                                                    handleSetOtherAmountDisable()
                                                                    handleFieldChange('donationAmount', donationAmount)
                                                                }
                                                            }}
                                                            active={values.donationAmount === donationAmount && !otherAmountActive}
                                                     />
                                                    : <DonateOption key={i} center small title='Other'
                                                            onClick={() => {
                                                            // if a user changes the donation amount after he selected a designation
                                                                if (values.donationDesignations && values.donationDesignations.length === 1) {
                                                                // prevent double click on "Other" value must not be null or 0
                                                                    const designation = values.donationDesignations[0]
                                                                    designation.value = values.donationAmount
                                                                    // update first field, use callback to update extra state
                                                                    handleSetOtherAmountActive()
                                                                    handleFieldChange('donationAmount', designation.value, () => {
                                                                        handleFieldChange('donationDesignations', [designation])
                                                                    })
                                                                } else {
                                                                    handleSetOtherAmountActive()
                                                                    handleFieldChange('donationAmount', null)
                                                                }
                                                            }}
                                                            active={otherAmountActive}
                                            />
                                            ))
                                        })}
                                    </div>
                                </Form.Field>
                            </Form.RowColumn>
                        </>
                    )}

                    {!props.theme.hiddenDonationAmounts && (otherAmountActive === true || (values.donationAmount === null || !donationAmountOptions.includes(values.donationAmount))) &&
                        <Form.CurrencyField
                            placeholder={t('fields.donation')}
                            type='currency'
                            defaultValue={values.donationAmount || ''}
                            onChange={value => {
                                // if a user changes the donation amount after he selected a designation
                                if (values.donationDesignations && values.donationDesignations.length === 1) {
                                    const designation = values.donationDesignations[0]
                                    designation.value = value
                                    // update first field, use callback to update extra state
                                    handleFieldChange('donationAmount', value, () => {
                                        handleFieldChange('donationDesignations', [designation])
                                    })
                                } else {
                                    handleFieldChange('donationAmount', value)
                                }
                            }}
                            currency={state.currency}
                            locale={state.locale}
                            required={typeof values.donationAmount !== 'undefined' && !donationAmountOptions.includes(values.donationAmount)}
                        />
                    }

                    {props.theme.hasDonationDisclaimer && (
                        <div style={{
                            color: props.theme.donationDisclaimerColor
                        }} className={styles.donationDisclaimer}>
                            {t(props.theme.donationDisclaimerFieldKey)}
                        </div>
                    )}

                    {!props.theme.hiddenDesignations &&
                        <>
                            <span className={cn(styles.title, {
                                [styles.isBoldTitle]: props.theme.isBoldTitle,
                                [styles.isTexasam]: props.theme.isTexasam
                            })}>{t('title3')}</span>
                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <Form.Dropdown
                                        placeholder={'Select'}
                                        options={state.campaign.website.donationDesignations}
                                        required={true}
                                        onChange={value => {
                                            handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                                type: value,
                                                value: values.donationAmount,
                                                other: value.indexOf('Other') === 0
                                            }] : null)
                                        }}
                                        defaultValue={values.donationDesignations
                                            ? (values.donationDesignations[0].type
                                                ? values.donationDesignations[0].other
                                                    ? 'Other'
                                                    : values.donationDesignations[0].type
                                                : null
                                            )
                                            : null
                                        }
                            />
                                </Form.Field>
                            </Form.RowColumn>
                        </>
                    }

                    {(values.donationDesignations?.[0].type.indexOf('Other') === 0 || values.donationDesignations?.[0].other) &&
                        <Form.RowColumn size={1}>
                            <Form.Field
                                required
                                defaultValue={values.donationDesignations
                                    ? (values.donationDesignations[0].type
                                        ? values.donationDesignations[0].type.indexOf('Other') === 0
                                            ? ''
                                            : values.donationDesignations[0].type
                                        : null
                                    )
                                    : null
                                }
                                onChange={value => {
                                    handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                        type: value,
                                        value: values.donationAmount,
                                        other: true
                                    }] : null)
                                }}
                                type='text'
                                placeholder={'Enter other designation'}
                            />
                        </Form.RowColumn>
                    }

                    {!props.theme.hiddenMatchingGift && (
                        <>
                            <span className={cn(styles.title, {
                                [styles.isBoldTitle]: props.theme.isBoldTitle,
                                [styles.isTexasam]: props.theme.isTexasam
                            })}>{t('title4')}</span>
                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <div className={styles.dontaionGift}>
                                        <DonateOption center small title='None'
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'none')}
                                            active={values.matchingGiftCompany === 'none'}
                                            className={styles.option1}
                                />
                                        <DonateOption center small title='My company'
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'own-company')}
                                            active={values.matchingGiftCompany === 'own-company'}
                                            className={styles.option3}
                                />
                                        <DonateOption center small title="Spouse’s company"
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'spouse-company')}
                                            active={values.matchingGiftCompany === 'spouse-company'}
                                            className={styles.option5}
                                />
                                    </div>
                                </Form.Field>

                                {(values.matchingGiftCompany === 'spouse-company' || values.matchingGiftCompany === 'own-company') && values.matchingGiftCompany !== null && values.matchingGiftCompany !== 'none'
                                    ? state.campaign['matching-gift-gateway']
                                        ? <MatchingGiftInput/>
                                        : <Form.Field
                                                defaultValue={values.matchingGiftCompanyName || null}
                                                onChange={value => {
                                                    handleFieldChange('matchingGiftCompanyName', `${value}`)
                                                }}
                                                type='text'
                                                placeholder={t('fields.companyName')}
                                />
                                    : null
                       }
                            </Form.RowColumn>
                        </>
                    )}

                    <span className={cn(styles.title, {
                        [styles.isBoldTitle]: props.theme.isBoldTitle,
                        [styles.isTexasam]: props.theme.isTexasam
                    })}>{t(props.theme.title5FieldKey)}</span>
                    <Form.RowColumn size={1} className={cn(styles.row1, {
                        [styles.hiddenClassYearField]: props.theme.hiddenClassYearField
                    })}>
                        <Form.Field
                            defaultValue={values.firstName || null}
                            onChange={value => {
                                handleFieldChange('firstName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.name')}
                                />
                        <Form.Field
                            defaultValue={values.lastName || null}
                            onChange={value => {
                                handleFieldChange('lastName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.lastname')}
                        />
                        {!props.theme.hiddenClassYearField && (
                            <Form.Field>
                                <Form.Dropdown
                                    placeholder={t('fields.year')}
                                    options={defaultYearOptions}
                                    required={false}
                                    onChange={value => {
                                        handleFieldChange('classYear', parseInt(value))
                                    }}
                                    defaultValue={values.classYear ? values.classYear.toString() : null}
                            />
                            </Form.Field>
                        )}
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row2}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            onChange={value => {
                                handleFieldChange('addressLine1', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.address')}
                                />
                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => {
                                handleFieldChange('addressLine2', `${value}`)
                            }}
                            type='number'
                            required={false}
                            placeholder={t('fields.unit')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.city || null}
                            onChange={value => {
                                handleFieldChange('city', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.city')}
                        />
                        <Form.Field
                            defaultValue={values.province || null}
                            onChange={value => {
                                handleFieldChange('province', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.state')}
                        />
                        <Form.Field
                            defaultValue={values.postalCode || null}
                            onChange={value => {
                                handleFieldChange('postalCode', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.zipcode')}
                        />
                    </Form.RowColumn>

                    {props.theme.hasContactTitle && (
                        <Form.RowColumn size={1}>
                            <span className={cn(styles.title, {
                                [styles.isBoldTitle]: props.theme.isBoldTitle
                            })}>{t('title7')}</span>
                        </Form.RowColumn>
                    )}

                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={values.homePhone || null}
                            onChange={value => {
                                handleFieldChange('homePhone', `${value}`)
                            }}
                            type='phone'
                            placeholder={t(props.theme.phoneFieldKey)}
                        />
                        <Form.Field
                            defaultValue={values.email || null}
                            onChange={value => {
                                handleFieldChange('email', `${value}`)
                            }}
                            type='email'
                            placeholder={t('fields.email')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1}>
                        <span className={styles.chekbox}>
                            {!props.theme.hiddenCheckbox && (
                                <>
                                    <Form.Checkbox
                                        defaultChecked={values.anonymousDonation}
                                        onChange={() => {
                                            handleFieldChange('anonymousDonation', !values.anonymousDonation)
                                        }}
                                />
                                    <p className={cn({
                                        [styles.isLighterCheckbox]: props.theme.isLighterCheckbox,
                                        [styles.isTexasam]: props.theme.isTexasam
                                    })} style={{
                                        color: props.theme.checkboxTextColor
                                    }}>{t(props.theme.checkboxTranslationFieldKey)}</p>
                                </>
                            )}
                        </span>
                    </Form.RowColumn>

                    <span className={cn(styles.title, styles.checkboxTitle, {
                        [styles.isBoldTitle]: props.theme.isBoldTitle
                    })}>{t(props.theme.commentsTranslationFieldKey)}</span>

                    <Form.RowColumn size={1}>
                        <Form.Textarea
                            defaultValue={values.comments || null}
                            onChange={handleComments}
                            type='textarea'
                            required={false}
                            rows={9}
                            placeholder={t('fields.comments.placeholder')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <div className={styles.separator} />

            <Form.Row>
                <Form.RowColumns>
                    <Form.Submit
                        onClick={handleSubmit}
                        isLoading={loading}
                        label={t('fields.continue')}
                    />
                </Form.RowColumns>
            </Form.Row>
        </div>
    )
}

LeadGenForm.defaultProps = {
    values: {
        matchingGift: 'None',
        anonymousDonation: false
    }
}

export default withForm(false)(withTheme(supportedThemes)(LeadGenForm))
