// @flow

import React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

import themes, { Theme } from './themes/__supportedThemes'

type Props = {
  value: string,
  label: string,
  theme: Theme,
}

const DonationBox = (props: Props) => {
    return (
        <div className={cn(styles.donationBox, {
            [styles.isTextOnly]: props.theme.isTextOnly,
            [styles.hasLargeText]: props.theme.hasLargeText,
            [styles.hiddenValue]: props.theme.hiddenValue,
            [styles.isTexasam2204]: props.theme.isTexasam2204,
        })}>
            {!props.theme.isTextOnly && (
                <span className={cn(styles.amount, {
                    [styles.isBolder]: props.theme.isBolder,
                    [styles.hasSmallTitle]: props.theme.hasSmallTitle,
                    [styles.hasMediumSizeTitle]: props.theme.hasMediumSizeTitle,
                    [styles.isTexasam2111]: props.theme.isTexasam2111,
                    [styles.isTexasam2204]: props.theme.isTexasam2204,
                })} style={{
                    color: props.theme.amountColor,
                    fontFamily: props.theme.valueFont
                }} dangerouslySetInnerHTML={{
                    __html: props.value
                }} />
            )}

            {!props.theme.hiddenValue && (
                <span className={cn(styles.text, {
                    [styles.isTiny]: props.theme.isTiny,
                    [styles.isTextOnly]: props.theme.isTextOnly,
                    [styles.isWide]: props.theme.isWide,
                    [styles.isDC1111]: props.theme.isDC1111,
                    [styles.isTexasam2204]: props.theme.isTexasam2204,
                })} style={{
                    color: props.theme.textColor,
                    fontFamily: props.theme.textFont
                }} dangerouslySetInnerHTML={{
                    __html: props.label
                }} />
            )}
        </div>
    )
}

export default withTheme(themes)(DonationBox)
