// @flow
import * as React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

import withForm from 'components/Form/Helpers/FormHOC'
import Content from 'components/Content'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import { Store } from 'Store'

function Footer (props) {
    const { theme } = props
    const { state } = React.useContext(Store)
    const address = state.campaign.client.address

    return (
        <div className={cn(styles.container, {
            [styles.hiddenLogo]: !theme.logo,
            [styles.hasBigTitle]: theme.hasBigTitle,
            [styles.IsBackgroundPositonTop]: theme.IsBackgroundPositonTop,
            [styles.hiddenMessage]: theme.hiddenMessage,
            [styles.iswayland2104logo]: theme.iswayland2104logo,
            [styles.isSalisbury2105Logo]: theme.isSalisbury2105Logo,
            [styles.isEVCC2106Logo]: theme.isEVCC2106Logo,
            [styles.isNEMCC2109Logo]: theme.isNEMCC2109Logo,
        })} style={{
            backgroundImage: `url(${theme.backgroundImage})`
        }}>

            {theme.logo && (
                <img
                    className={cn(styles.logo, {
                        [styles.isLogan2103Logo]: theme.isLogan2103Logo,
                        [styles.isLamar2103Logo]: theme.isLamar2103Logo,
                        [styles.iswayland2104logo]: theme.iswayland2104logo,
                        [styles.isEVCC2106Logo]: theme.isEVCC2106Logo,
                        [styles.isNEMCC2109Logo]: theme.isNEMCC2109Logo,
                        [styles.isFUS2105Logo]: theme.isFUS2105Logo,
                        [styles.isdc2111Logo]: theme.isdc2111Logo,
                        [styles.isTexasam2111]: theme.isTexasam2111,
                    })}
                    src={theme.logo}
                    alt=''
                />
            )}

            {!theme.hiddenMessage && (
                <div className={styles.innerContainer}>
                    <Content
                        className={cn(styles.disclaimer, {
                            [styles.isBold]: theme.isBold,
                            [styles.hasBigTitle]: theme.hasBigTitle,
                            [styles.isBlackTitle]: theme.isBlackTitle,
                            [styles.isWide]: theme.isWide,
                            [styles.isTexasam2111]: theme.isTexasam2111,
                        })}
                        contentKey='pageFooter.message'
                        wrapper='p'
                    >
                        With your help, we can support our students' dreams.
                    </Content>
                </div>
            )}

            <p className={cn(styles.text, {
                [styles.isLighterText]: theme.isLighterText
            })} style={{
                color: theme.disclaimerTextColor,
                backgroundColor: theme.disclaimerBackgroundColor
            }}>
                {theme.baseContactInformation
                    ? <span>
                        {theme.contactInformation.name && state.campaign.contactInfo.name ? <strong>{state.campaign.contactInfo.name} | </strong> : null}
                        {theme.contactInformation.position && state.campaign.contactInfo.position ? `${state.campaign.contactInfo.position} | ` : null}
                        {theme.contactInformation.phone && state.campaign.contactInfo.phone ? <>Ph: <a href={'tel:' + state.campaign.contactInfo.phone}>{state.campaign.contactInfo.phone}</a> | </> : null}
                        {theme.contactInformation.email && state.campaign.contactInfo.email ? <>Em: <a href={'mailto:' + state.campaign.contactInfo.email}>{state.campaign.contactInfo.email}</a></> : null}
                    </span>
                    : <span>{theme.contactInformation.address && [((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                        address.city,
                        address.province,
                        address.postalCode
                    ].join(' ')}
                        {theme.contactInformation.website && state.campaign.client.website ? <a href={state.campaign.client.website} target="_blank" rel="noopener noreferrer">{state.campaign.client.website}</a> : null}
                        {theme.contactInformation.phone && state.campaign.contactInfo.phone ? ` | Ph: ${state.campaign.contactInfo.phone}` : null}
                        {theme.contactInformation.email && state.campaign.contactInfo.email ? ` | Em: ${state.campaign.contactInfo.email}` : null}
                    </span>
                }
            </p>
        </div>
    )
}

export default withForm()(withTheme(supportedThemes)(Footer))
