// @flow
export default function getProperty (propertyName: string[], object: Object) {
    let [a, ...rest] = propertyName

    if (object === null) {
        return null
    }

    let property = object[a]

    if (typeof property === 'undefined') {
        return undefined
    }

    if (rest.length === 0) {
        return property
    }

    return getProperty(rest, property)
}
