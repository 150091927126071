export default {
    typeIdentifier: 'alumni_gift_2020_2',
    lead: {
        fields: {
            firstName: 'John',
            lastName: 'Shifty',
            province: 'FL',
            postalCode: '33160',
            previousDonationAmount: null,
            donationAmount: null,
            email: 'john.shifty@shiftone.io',
            dayPhone: '(555) 123 5678',
            classYear: 2005,
            addressLine1: '1 Graduate Street',
            city: 'Alma Mater',
            homePhone: '(123) 123-1231'
        }
    },
    campaign: {
        'payment-gateways': {
            'mock': {}
            // 'authorize.net': {
            //     apiLoginID: '3Rqc73Yz',
            //     clientKey: '5MHjmrNGXdJ94x8zLBC9LTpDhV3q3hcac6TPS5PKYcCc83khEn8Kf5N9s222FfGX'
            // }
            // cashnet: {
            //     storeUrl: 'https://train.cashnet.com/stockadecheckouttest',
            //     itemCode: 'STOCKADE'
            // }
            // blackbaud: {
            //     publicKey: '3516f521-d79b-4cd1-9915-6c41a8b1bc67',
            //     merchantId: 'bed81fce-c611-49b7-b200-5806c5694128'
            // }
            // nmi: {
            //     apiKey: 'jwygY4xkTHr322d9S5CCgeRAb6UG4SEZ',
            //     tokenizationKey: 'nVtwzU-TFhf85-Sen3Kx-2PAybA'
            // }
            // redirect: {
            //     url: 'https://shiftone.io'
            // }
            // stripe: {
            //     publishableKey: 'pk_test_hDv1iHqf9WxqYRSuEa7rrEdf00PmeyOhSN'
            // }
            // 'safe.save': {
            //     publishableKey: '5mN8N7-jhr55W-N22pxX-uAW2s9',
            //     currency: 'USD'
            // }
            // paypal: {
            //     clientId: 'Aa9p4gbouIxIEp4oB_fGHuRtxUqM5XPNYWKmKSiLIusbzbtwbPqbs4c-eA0L3PEpLxnb-AM_G5mn8mYW',
            // }
        }
        // testimonials: [
        //     {
        //         'name': { 'last': 'Clint', 'first': 'Glenn' },
        //         'quote': '"Receiving a Logan scholarship was further confirmation that the chiropractic journey and career path is where I belong."',
        //         'position': 'Tri 5'
        //     },
        //     {
        //         'name': { 'last': 'Clint', 'first': 'Glenn' },
        //         'quote': '"Receiving a Logan scholarship was further confirmation that the chiropractic journey and career path is where I belong."',
        //         'position': 'Tri 5'
        //     },
        //     {
        //         'name': { 'last': 'Clint', 'first': 'Glenn' },
        //         'quote': '"Receiving a Logan scholarship was further confirmation that the chiropractic journey and career path is where I belong."',
        //         'position': 'Tri 5'
        //     }
        // ],
        // website: {
        //   'stats': [
        //     {
        //       'label': 'Total Gifts to Ice Hockey from Alumni and Friends',
        //       'value': '$370,000',
        //       'highlight': false
        //     },
        //     {
        //       'label': 'Number of Alumni and Friends who Give to Ice Hockey',
        //       'value': '2,532',
        //       'highlight': false
        //     },
        //     {
        //       'label': 'Average GPA of Men\'s and Women\'s Ice Hockey Players',
        //       'value': '3.4',
        //       'highlight': false
        //     },
        //   ],
        //   'sidebar': {
        //     'stats' : {
        //       'header': 'Shift University\'s student athletes greatly benefit from the generous support of alumni and friends like you.',
        //       'description': '"Words don\'t fully express how much I appreciate the generosity of alumni and friends, which helps me be able to afford my education and compete on the ice with my teammates"',
        //       'footer': 'John Thompson, \'20'
        //     }
        //   },
        //   'masthead': {
        //     'heading': '{{ lead.fields.firstName }}, Shift Marketing Can Help Engage Your Donors.',
        //     'headingEmpty': 'Shift Marketing Can Help Engage Your Donors.',
        //     'message': 'Shift Marketing helps colleges and universities raise the funds needed to support athletics using the best mail, email, texting, online giving experiences and phonathon capabilities available. The education your student athletes receive will prepare them for whatever their futures may hold. Work with Shift Marketing to help engage your donors and support your athletes.',
        //   },
        //   'pageFooter': {
        //     'message': "{{ lead.fields.firstName }}, Shift Marketing Can Help Engage Your Donors."
        //   },
        //   donationAmounts: [250, 100, 50, 25],
        //   'donationDesignations': ['Men’s Ice Hockey Program', 'Women’s Ice Hockey Program', 'Divide Gift Equally Between Both Programs']
        // }
    }
}
