// @flow
import React from 'react'
import cn from 'classnames'
import FontFaceObserver from 'fontfaceobserver'

import styles from './styles.module.scss'

let unmounted = true

type Props = {
    className?: string,
    backgroundImage: string,
    text: string,
    font: { name: string, file: string, size: number, lineHeight: number },
    position: { x: number, y: number, rotate: number },
    debug: boolean
}

export default function DynamicTextImage (props: Props) {
    const canvasRef = React.useRef()

    React.useEffect(() => {
        unmounted = false
        const style = document.createElement('style')

        style.innerHTML = `
            @font-face { font-family: ${props.font.name}; src: url(${props.font.file});}
        `

        document.head.appendChild(style)

        const draw = function () {
            if (unmounted) return
            const canvas: HTMLCanvasElement = canvasRef.current

            const ctx = canvas.getContext('2d')

            const background = new Image()
            background.src = props.backgroundImage

            background.onload = function () {
                canvas.height = background.height
                canvas.width = background.width

                ctx.drawImage(background, 0, 0)

                ctx.font = `${props.font.size}px ${props.font.name}`
                ctx.fillStyle = props.font.textColor

                ctx.textAlign = 'center'
                ctx.textBaseline = 'middle'

                ctx.translate(props.position.x, props.position.y)

                ctx.rotate(degreesToRadians(props.position.rotate))
                const lines: string[] = props.text.split('\n')

                for (let i in lines) {
                    if (!lines.hasOwnProperty(i)) continue
                    ctx.fillText(lines[i], 0, props.font.lineHeight * (i - (lines.length - 1) / 2))
                }

                if (props.debug) {
                    ctx.rect(-1, -1, 2, 2)
                    ctx.rect(-150, -150, 300, 300)
                    ctx.stroke()
                }

                // reset canvas to initial coordinates
                ctx.translate(-props.position.x, -props.position.y)
                ctx.rotate(0)
            }
        }

        try {
            const font = new FontFaceObserver(props.font.name)

            font.load().then(draw, () => {
                console.log('Font is unavailable')
            })
        } catch (e) {
            console.log(e)
        }

        return () => {
            unmounted = true
            style.remove()
            if (document.fonts) {
                document.fonts.removeEventListener('loadingdone', draw)
            }
        }
    }, [props])

    return <canvas onClick={props.onClick} className={cn(styles.canvas, props.className)} ref={canvasRef} />
}

DynamicTextImage.defaultProps = {
    debug: false,
    textColor: '#000000'
}

function degreesToRadians (degrees: number): number {
    return degrees * Math.PI / 180
}
