// @flow
import React from 'react'
import getProperty from 'util/propertyAccessor'
import { Store } from 'Store'

type Props = {
    contentKey: string,
    children: ?React.Children,
    wrapper: any
}

export default function Content (props: Props) {
    const { state } = React.useContext(Store)
    const website = state.campaign.website
    const { contentKey, children, wrapper, ...wrapperProps } = props

    let content = website ? getProperty(contentKey.split('.'), website) : null

    if (content) {
        content = content.replace(/\{\{\s+(.*)\s+\}\}/, (match, tag) => {
            return getProperty(tag.split('.'), state)
        })

        wrapperProps.dangerouslySetInnerHTML = { __html: content }
    } else if (!children) {
        return null
    }

    return <props.wrapper {...wrapperProps}>{!content ? children : null}</props.wrapper>
}

Content.defaultProps = {
    wrapper: 'div'
}
