// @flow
import React from 'react'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes'

import './styles.scss'

function Input (props) {
    const { state } = React.useContext(Store)

    React.useEffect(() => {
        const matchingGiftGateway = state.campaign['matching-gift-gateway']

        if (typeof matchingGiftGateway === 'undefined') {
            return null
        }

        const script = document.createElement('script')
        script.setAttribute('src', 'https://doublethedonation.com/api/js/ddplugin.js')

        script.onload = function () {
            if (typeof window.doublethedonation !== 'undefined') {
                window.DDCONF = { API_KEY: matchingGiftGateway.publicKey }
                window.doublethedonation.plugin.load_streamlined_input(document.getElementById('dd-company-name-input'))
            }
        }

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [state.campaign])

    return (
        <span id='dd-custom-theme-wrapper' style={{
            '--primary-color': props.theme.primaryColor,
            '--secondary-color': props.theme.secondaryColor,
            '--ternary-color': props.theme.ternaryColor,
            '--selected-color': props.theme.selectedColor,
            '--color': props.theme.color,
            '--background-color': props.theme.backgroundColor
        }}>
            <div id='dd-company-name-input' />
        </span>
    )
}

export default withTheme(supportedThemes)(Input)
