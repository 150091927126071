// @flow
import * as React from 'react'

import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import DonationBox from 'components/DonationBox'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

DonationSummary.defaultProps = {
    date: new Date()
}

function DonationSummary (props: Props) {
    // const { theme } = props
    // const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { stats } = state.campaign.website

    if (props.theme.hiddenStats) return null

    return (
        <div className={styles.container}>
            {(stats || []).map(({ label , value }, index) => (
                <DonationBox
                    key={index}
                    label={label}
                    value={value}
                />
            ))}
        </div>
    )
}

export default withTheme(supportedThemes)(DonationSummary)
