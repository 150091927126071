import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import DonationBox from 'components/DonationBox'
import { Store } from 'Store'

import styles from './styles.module.scss'

export default function DonationSlider () {
    const { state } = React.useContext(Store)
    const { stats } = state.campaign.website
    return (
        <div className={styles.container}>
            <Swiper
                spaceBetween={20}
                slidesPerView={1.32}
                centeredSlides
                initialSlide={1}
                pagination={false}
            >
                {(stats || []).map(({ label , value }, index) => (
                    <SwiperSlide key={index}>
                        <DonationBox
                            key={index}
                            label={label}
                            value={value}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
