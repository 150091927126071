import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Store } from 'Store'
import Content from 'components/Content'
import cn from 'classnames'
import SwiperCore, {
    Autoplay,
    Pagination,
    Navigation
    // EffectCoverflow
} from 'swiper/core'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

SwiperCore.use([
    Autoplay,
    Pagination,
    Navigation
    // EffectCoverflow
])

function SideBar (props) {
    const { state } = React.useContext(Store)
    const name = state.lead.fields.firstName
    const { testimonials } = state.campaign

    return (
        <div style={{
            '--primary-color': props.theme.primaryColor,
            '--active-bubble-color': props.theme.activeBubbleColor,
            '--bubble-color': props.theme.bubbleColor,
            '--text-color': props.theme.textColor,
            backgroundColor: props.theme.sidebarBackgroundColor
        }} className={cn(styles.sideBarContainer, {
            [styles.hiddenHeader]: props.theme.hiddenHeader,
            [styles.isSmallBox]: props.theme.isSmallBox,
            [styles.isMediumSize]: props.theme.isMediumSize,
            [styles.isTexasam2111]: props.theme.isTexasam2111,
        })}>

            {props.theme.topLogo && (
                <div
                    className={styles.topLogo}
                    style={{ backgroundImage: `url(${props.theme.topLogo})` }}
                />
            )}

            {props.theme.withoutTestimonials && (
                <Content
                    contentKey='sidebar.stats.description'
                    wrapper='p'
                    className={styles.withoutTestimonialsDescription}
                >
                    Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </Content>
            )}

            {!props.theme.hiddenHeader &&
                <>
                    <Content
                        contentKey='sidebar.stats.header'
                        wrapper='h2'
                        style={{
                            color: props.theme.headerTextColor
                        }}
                        className={cn({
                            [styles.hasBoldHeader]: props.theme.hasBoldHeader,
                            [styles.hasLightHeader]: props.theme.hasLightHeader,
                            [styles.withoutTestimonialsHeader]: props.theme.withoutTestimonials,
                            [styles.isAADC2109Header]: props.theme.isAADC2109Header,
                            [styles.isAADC2202Header]: props.theme.isAADC2202Header,
                            [styles.isTexasam2111]: props.theme.isTexasam2111,
                        })}
                    >
                        {name}, your donation helps to make a difference
                        in our students lives.
                    </Content>

                    {!props.theme.withoutTestimonials && (
                        <div className={cn(styles.innerText, {
                            [styles.hasHigherDimensions]: props.theme.hasHigherDimensions,
                        })}>
                            <Swiper
                                slidesPerView={1}
                                loop={testimonials && testimonials.length >= 1}
                                pagination={(testimonials && testimonials.length >= 1) ? { clickable: true } : false}
                                autoplay={(testimonials && testimonials.length >= 1) ? {
                                    'delay': 4500,
                                    'disableOnInteraction': true,
                                    'pauseOnMouseEnter': true
                                } : false}
                                // effect={'coverflow'}
                                grabCursor={true}
                                // coverflowEffect={{
                                //     'rotate': 8,
                                //     'stretch': 0,
                                //     'depth': 55,
                                //     'modifier': 1,
                                //     'slideShadows': false
                                // }}
                            >
                                {testimonials && testimonials.map((testimonial, i) => (
                                    <SwiperSlide key={i}>
                                        <span className={cn(styles.box, {
                                            [styles.isLightStatsFooter]: true,
                                            [styles.isEVCC2106Stats]: props.theme.isEVCC2106Stats,
                                            [styles.isNemcc2109]: props.theme.isNemcc2109,
                                            [styles.isTexasam2111]: props.theme.isTexasam2111,
                                        })}>
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: testimonial.quote }} />

                                                <span
                                                    className={cn(styles.footer, {
                                                        [styles.isLightStatsFooter]: props.theme.isLightStatsFooter,
                                                        [styles.isEVCC2106Stats]: props.theme.isEVCC2106Stats,
                                                        [styles.isNemcc2109]: props.theme.isNemcc2109,
                                                    })}
                                                >
                                                    {testimonial.name && `${testimonial.name.first} ${testimonial.name.last}`}
                                                    {testimonial.position
                                                        ? testimonial.name ? `, ${testimonial.position}` : testimonial.position
                                                        : null
                                                    }
                                                </span>
                                            </div>
                                        </span>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </>
            }

            {props.theme.hiddenHeader &&
                <>
                    {!props.theme.withoutTestimonials && testimonials && testimonials.length > 0 && (
                        <Swiper
                            loop={testimonials.length > 1}
                            slidesPerView={1}
                            pagination={testimonials.length > 1 ? { clickable: true } : false}
                            autoplay={(testimonials && testimonials.length >= 1) ? {
                                'delay': 4500,
                                'disableOnInteraction': true,
                                'pauseOnMouseEnter': true
                            } : false}
                            // effect={'coverflow'}
                            grabCursor={true}
                            // coverflowEffect={{
                            //     'rotate': 8,
                            //     'stretch': 0,
                            //     'depth': 55,
                            //     'modifier': 1,
                            //     'slideShadows': false
                            // }}
                        >
                            {testimonials.map((testimonial, i) => (
                                <SwiperSlide key={i}>
                                    <span className={cn(styles.box, {
                                        [styles.isLightStatsFooter]: props.theme.isLightStatsFooter,
                                        [styles.hasBigTestimonials]: props.theme.hasBigTestimonials
                                    })}>
                                        <div>
                                            <p dangerouslySetInnerHTML={{ __html: testimonial.quote }} />

                                            <span
                                                className={cn(styles.footer, {
                                                    [styles.isLightStatsFooter]: props.theme.isLightStatsFooter
                                                })}
                                            >
                                                {testimonial.name && `${testimonial.name.first} ${testimonial.name.last}`}
                                                {testimonial.position
                                                    ? testimonial.name ? `, ${testimonial.position}` : testimonial.position
                                                    : null
                                                }
                                            </span>
                                        </div>
                                    </span>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    <Content
                        contentKey='sidebar.stats.header'
                        wrapper='h2'
                        className={cn(styles.h2, {
                            [styles.hiddenHeader]: props.theme.hiddenHeader,
                            [styles.hasBoldHeader]: props.theme.hasBoldHeader,
                            [styles.hasSmallTitle]: props.theme.hasSmallTitle,
                            [styles.isAADC2105Header]: props.theme.isAADC2105Header,
                            [styles.withoutTestimonialsHeader]: props.theme.withoutTestimonials,
                            [styles.isAADC2109Header]: props.theme.isAADC2109Header,
                            [styles.isTexasam2111]: props.theme.isTexasam2111,
                        })}
                        style={{
                            color: props.theme.headerTextColor
                        }}
                    >
                        {name}, your donation helps to make a difference
                        in our students lives.
                    </Content>
                    <Content
                        contentKey='sidebar.stats.footer'
                        wrapper='span'
                        className={cn(styles.footer, {
                            [styles.isLightStatsFooter]: props.theme.isLightStatsFooter,
                            [styles.isAADC2105Header]: props.theme.isAADC2105Header
                        })}
                    >
                        Rachel Thompson, ‘20
                    </Content>
                </>
            }

            {props.theme.withoutTestimonials && (
                <Content
                    contentKey='sidebar.stats.footer'
                    wrapper='p'
                    className={styles.withoutTestimonialsFooter}
                >
                    Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </Content>
            )}

            {!props.theme.hiddenBackgroundImage && (<div className={cn(styles.bg, {
                [props.theme.customBgImage]: props.theme.customBgImage,
            })} style={{ backgroundImage: `url(${props.theme.mask})` }} />)}
        </div>
    )
}

export default withTheme(supportedThemes)(SideBar)
