// @flow
import React from 'react'
import { Store } from 'Store'

export default function () {
    const { state } = React.useContext(Store)

    React.useEffect(() => {
        const matchingGiftGateway = state.campaign['matching-gift-gateway']

        if (typeof matchingGiftGateway === 'undefined') {
            return null
        }

        const script = document.createElement('script')
        script.setAttribute('src', 'https://doublethedonation.com/api/js/ddplugin.js')

        script.onload = function () {
            if (typeof window.doublethedonation !== 'undefined') {
                window.DDCONF = { API_KEY: matchingGiftGateway.publicKey }
            }
        }

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [state.campaign])

    return null
}
