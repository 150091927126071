import * as React from 'react'
import withTheme from 'hoc/withTheme'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store.js'

import withForm from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Success () {
    const { state } = React.useContext(Store)
    const { firstName, donationAmount, otherValue } = state.lead.fields // lastName
    const currentValue = `${donationAmount}`.toLocaleLowerCase() === 'other' ? otherValue : donationAmount
    const currencyFormatter = Currency(state.currency, state.locale)
    return (
        <>
            <div className={styles.text}>
                Thank you, {firstName}. <br />
                With your {currencyFormatter.format(currentValue)} donation, <br />
                we can make students' dreams come true.
            </div>
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Success))
