// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import Countdown from 'components/Countdown'
import AbsoluteTime from 'components/AbsoluteTime'

import { Store } from 'Store'

const TopBarWithCountdown = ({
    theme,
    date,
    t
}) => {
    const { state } = React.useContext(Store)
    return (
        <div className={styles.container}>
            <div className={styles.expiryText}>
                <div className={styles.expiryTextContainer}>
                    <span>{t('event_expiry')}&nbsp;<AbsoluteTime dateFormat='long' date={date} /></span>
                </div>
                <div className={styles.countdownContainer}>
                    <Countdown date={state.campaign.expiryDate} small />
                </div>
            </div>
            <div className={styles.imageContainer}>
                <img src={theme.logo} alt={'main logo'} className={styles.logo} />
            </div>
        </div>
    )
}

TopBarWithCountdown.defaultProps = {
    date: new Date()
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBarWithCountdown))
