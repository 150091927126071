// @flow
import React from 'react'
import { Store } from 'Store'
import { withRouter, Redirect } from 'react-router-dom'
import PaymentIntegration from '@shift-marketing/event-websites-payment'
import '@shift-marketing/event-websites-payment/dist/main.css'
import Url from 'url-parse'
import { processPayment, preProcessPayment, updateLead, processSubscription } from 'Actions'
import SuccessModal from '../SuccessModal'

function ShowPaymentForm (props) {
    const { state, dispatch } = React.useContext(Store)
    const paymentGateway = Object.keys(state.campaign['payment-gateways'])[0]
    const signOutUrl = new Url(state.campaignUrl.toString())

    signOutUrl.set('pathname', state.campaignUrl.pathname + '/success')
    signOutUrl.set('query', window.location.search)

    return <PaymentIntegration
        campaignReturnBackUrl={signOutUrl}
        paymentGatewayType={paymentGateway}
        paymentGateway={state.campaign['payment-gateways']}
        onFinish={paymentResponse => (<SuccessModal paymentResponse={paymentResponse}/>)}
        onAbort={() => {
            props.onAbort()
            return <div />
        }}
        onEmpty={() => (<Redirect to={`/${props.match.params.url}${props.location.search}`} push />)}
        onStart={() => {}}
        locale={state.locale}
        leadFields={state.lead.fields}
        apiActions={{
            preProcessPayment: preProcessPayment,
            processPayment: processPayment,
            updateLead: (code, lead) => updateLead(dispatch, code, lead),
            processSubscription: processSubscription
        }}
    />
}

export default withRouter(ShowPaymentForm)
